import { useEffect } from "react"
import { useRouter } from "next/router"
import Head from "next/head"
import Script from "next/script"
import AppNext from "next/app"
import { SessionProvider, useSession, signOut } from "next-auth/react"
import { MenuProvider, I18nProvider, VideoProvider } from "@/context"
// import { ProgressBar, Layout } from "@/globals"
import { Layout } from "@/globals"
import { GTM_ID, dlPush, pageview } from "@/lib/gtm"
import "nprogress/nprogress.css"
import "../styles/globals.css"
import { Icon, GlobalVideoModal } from "@/ui"
import Cookies from "js-cookie"
import NProgress from "nprogress"

export default function App({ Component, pageProps }) {
	// @todo: pageProps extract node && pass it to pageview
	const router = useRouter()
	const { locale } = router
	// const theCookie = Cookies.get("CookieConsent")
	const loadText = {
		ar: "جاري التحميل...",
		fr: "Chargement en cours...",
		en: "Loading...",
		it: "Caricamento in corso...",
		es: "Cargando...",
		nl: "Bezig met laden...",
		de: "Wird geladen...",
	}

	useEffect(() => {
		router.events.on("routeChangeComplete", pageview)
		return () => {
			router.events.off("routeChangeComplete", pageview)
		}
	}, [router.events])

	useEffect(() => {
		const cookieName = "CookieConsent"
		const cookie = Cookies.get(cookieName)
		const values = (cookie && JSON.parse(cookie)) || {
			necessary: true,
			analytics: false,
			marketing: false,
			preferences: false,
		}

		if (values && values.marketing) {
			dlPush("cookie_consent_given", { ...values })
		}
	}, [router.asPath])

	useEffect(() => {
		if (typeof window === "undefined") return
		// Retrieve UTM parameters from localStorage, if available.
		const savedUtms = localStorage.getItem("utms")
		const storedUtms = savedUtms ? JSON.parse(savedUtms) : {}
		// Get UTM parameters from the URL.
		let urlParams = window?.location?.search?.slice?.(1) || ""
		urlParams = new URLSearchParams(urlParams)
		// Check if at least one UTM parameter is present in the URL.
		const hasUtmInUrl =
			urlParams.has("utm_medium") ||
			urlParams.has("utm_source") ||
			urlParams.has("utm_campaign")
		const utmVals = hasUtmInUrl
			? {
					utm_medium: urlParams.get("utm_medium") || "",
					utm_source: urlParams.get("utm_source") || "",
					utm_campaign: urlParams.get("utm_campaign") || "",
			  }
			: {
					utm_medium: storedUtms?.utm_medium || "",
					utm_source: storedUtms?.utm_source || "",
					utm_campaign: storedUtms?.utm_campaign || "",
			  }

		// Store the new UTM parameters in localStorage
		localStorage.setItem("utms", JSON.stringify(utmVals))
	}, [])

	NProgress.configure({
		template: `<div class="bar" role="bar"> <div class="peg"></div> </div> <div class="spinner" role="spinner"> <div class="spinner-icon"></div><p>${loadText[locale]}</p> </div>`,
	})

	useEffect(() => {
		const handleRouteStart = () => NProgress.start()
		const handleRouteDone = () => NProgress.done()

		router.events.on("routeChangeStart", handleRouteStart)
		router.events.on("routeChangeComplete", handleRouteDone)
		router.events.on("routeChangeError", handleRouteDone)

		return () => {
			// Make sure to remove the event handler on unmount!
			router.events.off("routeChangeStart", handleRouteStart)
			router.events.off("routeChangeComplete", handleRouteDone)
			router.events.off("routeChangeError", handleRouteDone)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<>
			{/* Google Tag Manager - Global base code */}
			<Script
				strategy="afterInteractive"
				id={"gtm"}
				dangerouslySetInnerHTML={{
					__html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${GTM_ID}');
          `,
				}}
			/>

			<Head>
				<meta httpEquiv="X-UA-Compatible" content="IE=edge" />
				<meta
					name="viewport"
					content="width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no"
				/>
				<link rel="manifest" href="/manifest.webmanifest" crossOrigin="anonymous" />
				<link href="/icons/icon-48x48.png" rel="icon" type="image/png" sizes="48x48" />
				<link rel="apple-touch-icon" sizes="48x48" href="/icons/icon-48x48.png" />
				<link rel="apple-touch-icon" sizes="72x72" href="/icons/icon-72x72.png" />
				<link rel="apple-touch-icon" sizes="96x96" href="/icons/icon-96x96.png" />
				<link rel="apple-touch-icon" sizes="144x144" href="/icons/icon-144x144.png" />
				<link rel="apple-touch-icon" sizes="192x192" href="/icons/icon-192x192.png" />
				<link rel="apple-touch-icon" sizes="256x256" href="/icons/icon-256x256.png" />
				<link rel="apple-touch-icon" sizes="384x384" href="/icons/icon-384x384.png" />
				<link rel="apple-touch-icon" sizes="512x512" href="/icons/icon-512x512.png" />
				<meta name="theme-color" content="#e5583f" />
				<link rel="apple-touch-icon" href="/apple-icon.png"></link>
				{/* <link rel="prefetch" as="image/svg+xml" href="/icons.svg" /> */}
			</Head>
			{/* <ProgressBar /> */}
			<SessionProvider session={pageProps?.session}>
				<I18nProvider lngDict={pageProps?.i18n || {}} locale={pageProps?.locale}>
					<MenuProvider menus={pageProps?.menus || []}>
						<VideoProvider>
							<Layout {...pageProps}>
								<Component {...pageProps} />
								<GlobalVideoModal
									closeIcon={
										<Icon className="h-5 w-5 text-white fill-current" id="close" />
									}
									expenderIcon={
										<Icon className="h-5 w-5 text-white fill-current" id="enlarge" />
									}
									minimizerIcon={
										<Icon className="h-5 w-5 text-white fill-current" id="minimize" />
									}
								/>
							</Layout>
						</VideoProvider>
					</MenuProvider>
				</I18nProvider>
				<RefreshTokenHandler />
			</SessionProvider>
		</>
	)
}

const RefreshTokenHandler = () => {
	const { data: session } = useSession()
	// @todo: signIn need to be a prop
	// const signIn = useSignIn()

	useEffect(() => {
		if (session?.error === "RefreshAccessTokenError") {
			signOut() // Force sign out
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [session])

	return null
}

App.getInitialProps = async (appContext) => {
	const appProps = await AppNext.getInitialProps(appContext)
	return { ...appProps }
}
