
		import dynamic from "next/dynamic"

		import Mre_templates__accordion_with_background from "../components/modules/accordion_with_background/AccordionWithBackgroundWidget.jsx"
import Mre_templates__anchors from "../components/modules/anchors/AnchorWidget.jsx"
import Vactory_banner__default from "../components/modules/banner/BannerVariant1Widget.jsx"
import Mre_templates__conseils_filter from "../components/modules/conseils/ConseilsFilterWidget.jsx"
import Mre_templates__conseils_node_queue from "../components/modules/conseils/ConseilsNodeQueueWidget.jsx"
import Mre_templates__conseils_two_col_queue from "../components/modules/conseils/ConseilsTwoColQueueWidget.jsx"
import Mre_templates__cross_content_conseils from "../components/modules/conseils/CrossContentWidget.jsx"
import Mre_templates__contacts_utiles_list from "../components/modules/contacts_utiles/ContactsUtilesListWidget.jsx"
import Mre_templates__contacts_utiles_node_queue from "../components/modules/contacts_utiles/ContactsUtilesNodeQueueWidget.jsx"
import Vactory_dynamic_field_decoupled__cookie_compliance from "../components/modules/cookie-compliance/CookieComplianceWidget.jsx"
import Mre_templates__list_card_icons from "../components/modules/list_card_icons/ListCardIconsWidget.jsx"
import Mre_templates__cross_content_news from "../components/modules/news/CrossContentWidget.jsx"
import Vactory_news__block_slider from "../components/modules/news/NewsBlockSliderWidget.jsx"
import Mre_templates__news_prefiltred_block from "../components/modules/news/NewsPrefiltredBlockWidget.jsx"
import Vactory_news__three_columns from "../components/modules/news/NewsThreeColumnsWidget.jsx"
import Mre_templates__block_news_two_columns from "../components/modules/news/NewsTwoColumnsWidget.jsx"
import Mre_templates__notre_solution from "../components/modules/notre_solution/NotreSolutionWidget.jsx"
import Mre_templates__reclamation_assistance from "../components/modules/reclamation_assistance/ReclamationAssistanceWidget.jsx"
import Mre_templates__slider_card_icons from "../components/modules/slider_card_icons/SliderCardIconsWidget.jsx"
import Mre_templates__slider_cards_video from "../components/modules/slider_cards_video/SliderCardsVideoWidget.jsx"
import Mre_templates__slider_cards from "../components/modules/slider_cards/SliderCardsWidget.jsx"
import Mre_templates__ouvrir_compte from "../components/modules/webform/WebformOuvertureCompteWidget.jsx"
import Mre_templates__besoin_dinformations from "../components/widgets/besoin_dinformations/BesoinDinfoWidget.jsx"
import Mre_templates__contact_cards from "../components/widgets/contact_cards/ContactCardsWidget.jsx"
import Mre_templates__contacts_grid from "../components/widgets/contacts_grid/ContactGridWidget.jsx"
import Mre_templates__services_blocks from "../components/widgets/content/block/services/ServicesWidget.jsx"
import Mre_templates__list_cards from "../components/widgets/content/cards/card-default/CardWidget.jsx"
import Mre_templates__list_cards_inline from "../components/widgets/content/cards/card-inline/CardWidget.jsx"
import Mre_templates__list_cards_mosaique from "../components/widgets/content/cards/card-mosaic/CardWidget.jsx"
import Mre_templates__wysiwyg from "../components/widgets/content/wysiwyg/WysiwygWidget.jsx"
import Mre_templates__filiale_details from "../components/widgets/filiale_details/FilialeDetailsWidget.jsx"
import Mre_templates__filiale_three_col from "../components/widgets/filiale_three_col/FilialesWidget.jsx"
import Mre_templates__filiale_two_col from "../components/widgets/filiale_two_col/FilialesWidget.jsx"
import Mre_templates__find_agency from "../components/widgets/find_agency/FindAgencyWidget.jsx"
import Mre_templates__footer from "../components/widgets/footer/FooterVariant3Widget.jsx"
import Mre_templates__accordion from "../components/widgets/grouping/accordion/AccordionWidget.jsx"
import Mre_templates__header_lp_langs from "../components/widgets/header_lp_langs/HeaderLPMultiWidget.jsx"
import Mre_templates__header_lp from "../components/widgets/header_lp/HeaderLPWidget.jsx"
import Mre_templates__header from "../components/widgets/header/HeaderVariant4Widget.jsx"
import Mre_templates__image_links from "../components/widgets/image_links/ImageLinksWidget.jsx"
import Mre_templates__introduction from "../components/widgets/introduction/IntroductionWidget.jsx"
import Mre_templates__key_numbers from "../components/widgets/keyFigures/KeyFiguresWidget.jsx"
import Vactory_dynamic_field_decoupled__node_queue from "../components/widgets/node-queue/NodeQueueWidget.jsx"
import Mre_templates__packs from "../components/widgets/packs/PacksWidget.jsx"
import Mre_templates__select_redirect from "../components/widgets/pays-select/PaysSelectWidget.jsx"
import Mre_templates__share_links from "../components/widgets/share-links/ShareLinksWidget.jsx"
import Mre_templates__card_slider from "../components/widgets/slider-hp/sliderCardWidget.jsx"
import Mre_templates__slide_lp from "../components/widgets/slider-lp/sliderLPWidget.jsx"
import Mre_templates__slider_HP from "../components/widgets/sliderHP/SliderHPWidget.jsx"
import Mre_templates__tabs_content from "../components/widgets/tabs-content/TabsContentWidget.jsx"
import Mre_templates__toolbox from "../components/widgets/toolbox/v2/ToolboxVariant2Widget.jsx"
import Mre_templates__two_column_image from "../components/widgets/two-column-image/TwoColumnImageWidget.jsx"
import Mre_templates__video_content_section from "../components/widgets/video-content-section/VideoContentSectionWidget.jsx"

     export const Widgets = {
  "mre_templates:accordion_with_background":Mre_templates__accordion_with_background,
  "mre_templates:anchors":Mre_templates__anchors,
  "vactory_banner:default":Vactory_banner__default,
  "mre_templates:bonnes_affaires_list":dynamic(() => import("../components/modules/bonnes_affaires/BonnesAffairesListWidget.jsx")),
  "mre_templates:conseils_filter":Mre_templates__conseils_filter,
  "mre_templates:conseils_list":dynamic(() => import("../components/modules/conseils/ConseilsListWidget.jsx")),
  "mre_templates:conseils_node_queue":Mre_templates__conseils_node_queue,
  "mre_templates:conseils_two_col_queue":Mre_templates__conseils_two_col_queue,
  "mre_templates:cross-content-conseils":Mre_templates__cross_content_conseils,
  "mre_templates:contacts_utiles_list":Mre_templates__contacts_utiles_list,
  "mre_templates:contacts_utiles_node_queue":Mre_templates__contacts_utiles_node_queue,
  "vactory_dynamic_field_decoupled:cookie-compliance":Vactory_dynamic_field_decoupled__cookie_compliance,
  "vactory_faq:list":dynamic(() => import("../components/modules/faq/FaqListingWidget.jsx")),
  "mre_templates:faq_list":dynamic(() => import("../components/modules/faq/FaqListWidget.jsx")),
  "vactory_forums:forum_search_input":dynamic(() => import("../components/modules/forum/widgets/forumInputSearchWidget.jsx")),
  "vactory_forums:list":dynamic(() => import("../components/modules/forum/widgets/forumListWidget.jsx")),
  "mre_templates:forum_list":dynamic(() => import("../components/modules/forum/widgets/forumListWithFiltersWidget.jsx")),
  "vactory_forums:rooms":dynamic(() => import("../components/modules/forum/widgets/forumRoomsWidget.jsx")),
  "vactory_forums:search_results":dynamic(() => import("../components/modules/forum/widgets/forumSearchResultsWidget.jsx")),
  "mre_templates:list_card_icons":Mre_templates__list_card_icons,
  "vactory_locator:default":dynamic(() => import("../components/modules/locator/locatorWidget.jsx")),
  "mre_templates:cross-content-news":Mre_templates__cross_content_news,
  "vactory_news:block-slider":Vactory_news__block_slider,
  "mre_templates:news_list":dynamic(() => import("../components/modules/news/NewsListWidget.jsx")),
  "mre_templates:news_prefiltred_block":Mre_templates__news_prefiltred_block,
  "vactory_news:three-columns":Vactory_news__three_columns,
  "mre_templates:block_news_two_columns":Mre_templates__block_news_two_columns,
  "newsletter_webform:newsletter":dynamic(() => import("../components/modules/newsletter/NewsletterWidget.jsx")),
  "mre_templates:notre_solution":Mre_templates__notre_solution,
  "mre_templates:reclamation_assistance":Mre_templates__reclamation_assistance,
  "mre_templates:slider_card_icons":Mre_templates__slider_card_icons,
  "mre_templates:slider_cards_video":Mre_templates__slider_cards_video,
  "mre_templates:slider_cards":Mre_templates__slider_cards,
  "vactory_decoupled_webform:webform_confirmation":dynamic(() => import("../components/modules/webform/WebFormConfirmationWidget.jsx")),
  "vactory_decoupled_webform:webform":dynamic(() => import("../components/modules/webform/WebformDefaultWidget.jsx")),
  "mre_templates:webform_envoyer_message":dynamic(() => import("../components/modules/webform/WebformEnvoyerMessageWidget.jsx")),
  "mre_templates:webform_rappele_par_conseiller":dynamic(() => import("../components/modules/webform/WebformEtreRappeleWidget.jsx")),
  "mre_templates:ouvrir-compte":Mre_templates__ouvrir_compte,
  "mre_templates:besoin_dinformations":Mre_templates__besoin_dinformations,
  "mre_templates:contact_cards":Mre_templates__contact_cards,
  "mre_templates:contacts_grid":Mre_templates__contacts_grid,
  "mre_templates:services_blocks":Mre_templates__services_blocks,
  "mre_templates:list_cards":Mre_templates__list_cards,
  "mre_templates:list_cards_inline":Mre_templates__list_cards_inline,
  "mre_templates:list_cards_mosaique":Mre_templates__list_cards_mosaique,
  "mre_templates:wysiwyg":Mre_templates__wysiwyg,
  "mre_templates:filiale_details":Mre_templates__filiale_details,
  "mre_templates:filiale_three_col":Mre_templates__filiale_three_col,
  "mre_templates:filiale_two_col":Mre_templates__filiale_two_col,
  "mre_templates:find_agency":Mre_templates__find_agency,
  "mre_templates:footer":Mre_templates__footer,
  "mre_templates:accordion":Mre_templates__accordion,
  "mre_templates:header_lp_langs":Mre_templates__header_lp_langs,
  "mre_templates:header_lp":Mre_templates__header_lp,
  "mre_templates:header":Mre_templates__header,
  "mre_templates:image_links":Mre_templates__image_links,
  "mre_templates:introduction":Mre_templates__introduction,
  "mre_templates:key_numbers":Mre_templates__key_numbers,
  "vactory_dynamic_field_decoupled:node-queue":Vactory_dynamic_field_decoupled__node_queue,
  "mre_templates:packs":Mre_templates__packs,
  "mre_templates:select_redirect":Mre_templates__select_redirect,
  "mre_templates:share-links":Mre_templates__share_links,
  "mre_templates:card_slider":Mre_templates__card_slider,
  "mre_templates:slide_lp":Mre_templates__slide_lp,
  "mre_templates:slider_HP":Mre_templates__slider_HP,
  "mre_templates:tabs_content":Mre_templates__tabs_content,
  "mre_templates:toolbox":Mre_templates__toolbox,
  "mre_templates:two_column_image":Mre_templates__two_column_image,
  "mre_templates:video_content_section":Mre_templates__video_content_section,
}
