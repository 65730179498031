import { Fragment, useState, useEffect, useRef } from "react"
import { useNode, useI18n, useMenu, useHeader /*, useAccount*/ } from "@/hooks"
import { getEnabledLanguages } from "@/lib"
import { useRouter } from "next/router"
import Image from "next/image"
import { Menu, Transition } from "@headlessui/react"
import { /*Avatar, */ Icon, Link, Hamburger, Text, SearchOverlay } from "@/ui"
import clsx from "clsx"

export const config = {
	id: "mre_templates:header",
}

const languages = getEnabledLanguages({
	withLabels: true,
})

// const UserInfo = () => {
// 	const { t } = useI18n()
// 	const { signOut, loginUrl, accountUrl, profile, isAuthenticated } = useAccount()

// 	const userNavigation = [
// 		{ name: t("Nx:Settings"), href: accountUrl },
// 		{ name: t("Nx:Sign out"), href: "#.", onClick: signOut },
// 	]

// 	if (isAuthenticated) {
// 		return (
// 			<Menu
// 				as="div"
// 				className="inline-block flex-shrink-0 relative ml-4 px-1 md2:px-2 py-1.5 z-[100]"
// 			>
// 				<Menu.Button className="bg-gray-800 rounded-full flex text-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
// 					<Text as="span" className="sr-only">
// 						Open user menu
// 					</Text>
// 					{profile?.user?.avatar ? (
// 						<Avatar src={profile.user.avatar} size="normal" alt="Me" />
// 					) : (
// 						<Avatar variant="placeholder" size="normal" />
// 					)}
// 				</Menu.Button>
// 				<Transition
// 					unmount={false}
// 					as={Fragment}
// 					enter="transition ease-out duration-100"
// 					enterFrom="transform opacity-0 scale-95"
// 					enterTo="transform opacity-100 scale-100"
// 					leave="transition ease-in duration-75"
// 					leaveFrom="transform opacity-100 scale-100"
// 					leaveTo="transform opacity-0 scale-95"
// 				>
// 					<Menu.Items className="z-10	origin-top-right absolute right-0 mt-2 w-48 shadow-lg bg-white ring-1 ring-black ring-opacity-5 py-1 divide-y divide-gray-100 focus:outline-none">
// 						<div className="py-1">
// 							<Menu.Item>
// 								<Text
// 									as="span"
// 									className="block px-4 py-2 text-sm text-gray-900 font-bold"
// 								>
// 									{profile?.user?.full_name}
// 								</Text>
// 							</Menu.Item>
// 						</div>
// 						<div className="py-1">
// 							{userNavigation.map((item) => (
// 								<Menu.Item key={item.name}>
// 									{({ active }) => (
// 										<Link href={item?.href} passHref>
// 											<a
// 												onClick={item?.onClick}
// 												className={`${
// 													active ? "bg-gray-100 text-secondary" : "text-gray-900"
// 												} block px-4 py-2 text-sm hover:bg-gray-100 hover:text-secondary`}
// 											>
// 												{item.name}
// 											</a>
// 										</Link>
// 									)}
// 								</Menu.Item>
// 							))}
// 						</div>
// 					</Menu.Items>
// 				</Transition>
// 			</Menu>
// 		)
// 	}

// 	return (
// 		<div className="flex items-center flex-shrink-0">
// 			<Link
// 				href={loginUrl}
// 				className="whitespace-nowrap inline-flex items-center justify-center px-4 py-3 text-base font-medium text-black bg-primary hover:bg-yellow-500"
// 			>
// 				<svg
// 					className="md2:mr-2.5 h-4 w-4 inline-block"
// 					xmlns="http://www.w3.org/2000/svg"
// 					fill="none"
// 					viewBox="0 0 24 24"
// 					stroke="currentColor"
// 					width="16"
// 					height="16"
// 				>
// 					<path
// 						strokeLinecap="round"
// 						strokeLinejoin="round"
// 						strokeWidth="2"
// 						d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
// 					></path>
// 				</svg>
// 				<Text as="span" className="hidden md2:inline">
// 					{t("Nx:Se connecter")}
// 				</Text>
// 			</Link>
// 		</div>
// 	)
// }

const SearchButton = ({ color = "white", onClick }) => (
	<button
		onClick={onClick}
		className="inline-flex items-center p-2 text-black bg-transparent hover:bg-white hover:bg-opacity-20 fo-friendly md2:text-white"
	>
		<Icon
			id="search"
			className={clsx(
				"fill-current h-5 w-5",
				color === "white" ? "text-white" : "text-black"
			)}
			height="20px"
			width="20px"
		/>
		<Text as="span" className="sr-only">
			Search
		</Text>
	</button>
)

const HeaderComponent = ({ data }) => {
	const [isStickyOverlay, setIsStickyOverlay] = useState(false)
	// const { activeLocale: currentLanguage } = useI18n()
	// const router = useRouter()
	// const [keywords, setKeywords] = useState([])

	// const getSuggestions = async () => {
	// 	const response = await drupal.fetch(
	// 		`/${router.locale}/frequent-searches?index=default_content_index&limit=10&lang=${currentLanguage}`
	// 	)
	// 	const { keywords } = await response.json()
	// 	setKeywords(keywords)
	// }

	const props = {
		mainMenu: data?.extra_field?.use_menu,
		logo: {
			src: data?.extra_field?.header_logo?.[0]?._default || null,
			width: data?.extra_field?.header_logo?.[0]?.meta?.width,
			height: data?.extra_field?.header_logo?.[0]?.meta?.height,
			alt: data?.extra_field?.header_logo?.[0]?.meta?.alt,
		},
		logo_dark: {
			src: data?.extra_field?.header_logo_dark?.[0]?._default || null,
			width: data?.extra_field?.header_logo_dark?.[0]?.meta?.width,
			height: data?.extra_field?.header_logo_dark?.[0]?.meta?.height,
			alt: data?.extra_field?.header_logo_dark?.[0]?.meta?.alt,
		},
		ctaSocialLabel: data?.extra_field?.cta_social_label,
		slogon: data?.extra_field?.slogon,
		menu_slogon: data?.extra_field?.menu_slogon,
		switchLocation: data?.extra_field?.show_language_dropdown,
		showAccount: data?.extra_field?.show_account,
		//hiding account for V0
		// showAccount: false,
		link: data?.extra_field?.cta,
		top_navigation: data?.extra_field?.top_navigation,
		dfComponentsCtaSocial: data?.components.map((item) => ({
			link: {
				title: item?.cta_social?.title,
				href: item?.cta_social?.url || null,
				id: item?.icon_id || "",
				className: item?.cta_social?.attributes?.class || "",
				rel: item?.cta_social.attributes?.rel || "",
				target: item?.cta_social.attributes?.target || "_self",
			},
		})),
	}

	const [isSearchOverlayVisible, setIsSearchOverlayVisible] = useState(false)

	useEffect(() => {
		document.addEventListener("keyup", (e) => {
			if (e.key === "Escape") {
				setIsSearchOverlayVisible(false)
			}
		})
	}, [isSearchOverlayVisible])

	// useEffect(() => {
	// 	getSuggestions()
	// }, [])

	return (
		<>
			<Header
				{...props}
				setIsStickyOverlay={setIsStickyOverlay}
				isSearchOverlayVisible={isSearchOverlayVisible}
				setIsSearchOverlayVisible={setIsSearchOverlayVisible}
			/>
			<SearchOverlay
				setIsSearchOverlayVisible={setIsSearchOverlayVisible}
				isStickyOverlay={isStickyOverlay}
				// suggestions={keywords}
				show={isSearchOverlayVisible}
				onClose={() => {
					setIsSearchOverlayVisible(false)
				}}
			/>
		</>
	)
}

const Header = ({
	setIsSearchOverlayVisible,
	isSearchOverlayVisible,
	mainMenu,
	ctaSocialLabel,
	slogon,
	menu_slogon,
	switchLocation,
	logo,
	logo_dark,
	// showAccount,
	top_navigation,
	link,
	dfComponentsCtaSocial,
	setIsStickyOverlay,
}) => {
	const router = useRouter()
	const locale = router.locale
	const navigation = useMenu(mainMenu)
	const topnavigation = useMenu(top_navigation)

	// if true, show mobile menu
	const [showMobileList, setShowMobileList] = useState(false)

	// To hide/animate the header
	const { headerState } = useHeader(100)

	//Prevent body scrolling on mobile header open
	useEffect(() => {
		const desktop = window.matchMedia("(min-width: 992px)").matches
		;(showMobileList && !desktop) || isSearchOverlayVisible
			? document.body.classList.add("overflow-y-hidden")
			: document.body.classList.remove("overflow-y-hidden")
	}, [showMobileList, isSearchOverlayVisible])

	const { t } = useI18n()

	const [isPulled, setIsPulled] = useState(false)
	const [showID, setShowID] = useState(null)

	useEffect(() => {
		setIsPulled(headerState !== "top" ? false : true)
	}, [headerState])

	const isSticky = !headerState.includes("top") || headerState.includes("scroll")
	setIsStickyOverlay(isSticky)
	const headerRef = useRef(null)

	const showOverlay = () => {
		setShowID(null)
		setIsSearchOverlayVisible(true)
	}

	return (
		<header className="z-[51] absolute top-0 left-0 w-full">
			<div
				ref={headerRef}
				className={clsx(
					{
						"-top-32": isSticky && !isPulled,
						"-top-12": isSticky && isPulled,
					},
					isSticky ? "fixed inset-x-0 bg-gradient-to-tr bg-white" : "relative",
					"flex flex-col",
					"z-[51]"
				)}
			>
				<TopBar
					topnavigation={topnavigation}
					dfComponentsCtaSocial={dfComponentsCtaSocial}
					ctaSocialLabel={ctaSocialLabel}
					link={link}
					switchLocation={switchLocation}
					// showAccount={showAccount}
					showOverlay={showOverlay}
				/>
				<div>
					<div
						className={clsx(
							isSticky ? "" : "md2:pt-5 md2:pb-4",
							"lg:container md2:px-5 flex items-center",
							"border-b md2:border-b-0 border-gray-300 border-opacity-50 relative z-[52]"
						)}
					>
						<Link
							className="flex ltr:border-r rtl:border-l md2:ltr:border-0 md2:rtl:border-0 border-gray-300 border-opacity-50 p-3 md2:pl-0"
							href={`/${locale}`}
						>
							<Text as="span" className="sr-only">
								{t("Nx:Portail MRE par Attijariwafa Bank")}
							</Text>

							<div className="divide-x-2 divide-white divide-solid hidden md2:flex items-center">
								<div className="h-14 pr-8 hidden md2:block">
									<Image src={logo.src} alt={logo.alt} width="175" height="56" />
								</div>

								{slogon && (
									<div className="font-bold text-xl pl-4 md2:text-white">
										<Text as="span" variant="large">
											{slogon}
										</Text>
									</div>
								)}
							</div>
							<div className="md2:hidden flex items-center py-[4.158px]">
								<Image src={logo.src} alt={logo.alt} width="96" height="32" />
							</div>
						</Link>
						<div className="hidden md2:flex md2:items-center md2:ml-auto">
							{locale.toLocaleLowerCase() !== "tfng" && (
								<>
									<SearchButton onClick={showOverlay} />
								</>
							)}
							{/* <div className="relative inline-block text-left fo-friendly"> */}
							{switchLocation == 1 && (
								<>
									<hr aria-orientation="vertical" className="w-px h-5 bg-gray-300 mx-4" />
									<SwitchLocale />
								</>
							)}
							{/* </div> */}
						</div>
						<div
							className={clsx(
								"md2:hidden",
								isSticky ? "" : "d-flex",
								"flex justify-between flex-1 md2:flex-none px-3",
								"text-white"
							)}
						>
							<Text
								as="p"
								className={clsx(
									locale === "ar" && "slogan",
									"text-base font-bold leading-snug mr-5 w-[106px]"
								)}
							>
								{menu_slogon}
							</Text>
							<Hamburger
								onClick={() => setShowMobileList(true)}
								isOpen={showMobileList}
							/>
						</div>
					</div>
					{locale.toLocaleLowerCase() !== "tfng" && (
						<div>
							<MainMenu
								showID={showID}
								setShowID={setShowID}
								showMobileList={showMobileList}
								setShowMobileList={setShowMobileList}
								navigation={navigation}
								isSticky={isSticky}
								menu_slogon={menu_slogon}
								hideSearch={() => setIsSearchOverlayVisible(false)}
							/>
							<FlyoutMenus
								showID={showID}
								setShowID={setShowID}
								setShowMobileList={setShowMobileList}
								isSticky={isSticky}
							/>
						</div>
					)}
				</div>
				<div
					className={clsx(
						isSticky ? "translate-y-0" : "-translate-y-24",
						"hidden md2:block md2:bg-white md2:fixed md2:top-0 md2:z-[52] md2:w-full transition-transform transform duration-2000"
					)}
				>
					<div className={isSticky ? "md2:shadow-gray" : ""}>
						<div
							className={clsx(
								"lg:container md2:px-5 flex",
								"border-b md2:border-b-0 border-gray-300 border-opacity-50"
							)}
						>
							<Link
								href={`/${locale}`}
								className="flex ltr:border-r rtl:border-l md2:ltr:border-0 md2:rtl:border-0 border-gray-300 border-opacity-50 p-3"
							>
								<Text as="span" className="sr-only">
									{t("Nx:Portail MRE par Attijariwafa Bank")}
								</Text>
								<div className="divide-x-2 divide-gray-700 divide-solid hidden md2:flex items-center">
									<div className="h-14 w-auto hidden md2:block pr-8">
										<Image
											src={logo_dark.src ? logo_dark.src : logo.src}
											alt={logo_dark.alt ? logo_dark.alt : logo.alt}
											height="56"
											width="175"
										/>
									</div>
									{slogon && (
										<div className={clsx("font-bold text-xl pl-4", "md2:text-black")}>
											<Text as="span" variant="large">
												{slogon}
											</Text>
										</div>
									)}
								</div>
							</Link>

							<div className="hidden md2:flex md2:items-center md2:ml-auto">
								{locale.toLocaleLowerCase() !== "tfng" && (
									<SearchButton onClick={showOverlay} color="black" />
								)}
								{switchLocation == 1 && (
									<>
										<hr
											aria-orientation="vertical"
											className={clsx(
												locale.toLocaleLowerCase() === "tfng" && "hidden",
												"w-px h-5 bg-gray-300 mx-4"
											)}
										/>
										<SwitchLocale isSticky={isSticky} />
									</>
								)}
							</div>
							<div className="w-px bg-gray-200 mr-5 ml-5 hidden md2:block"></div>
							<div
								className={clsx(
									isSticky ? "" : "md2:hidden",
									"flex justify-between items-center flex-1 md2:flex-none px-3",
									isSticky ? "md2:text-black" : "text-white"
								)}
							>
								<Text
									as="p"
									className={clsx(
										isSticky ? "md2:text-black" : "",
										"text-base font-bold leading-snug mr-5 w-[106px]"
									)}
								>
									{menu_slogon}
								</Text>

								{locale.toLocaleLowerCase() !== "tfng" && (
									<Hamburger onClick={() => setIsPulled(!isPulled)} isOpen={isPulled} />
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</header>
	)
}

const MainMenu = ({
	showMobileList,
	setShowMobileList,
	navigation,
	isSticky,
	showID,
	setShowID,
	menu_slogon,
	hideSearch,
}) => {
	const router = useRouter()

	useEffect(() => {
		const handleResize = () => {
			const desktop = window.matchMedia("(min-width: 992px)").matches

			setShowMobileList(desktop ? true : showMobileList)
		}

		window.addEventListener("resize", handleResize)
		handleResize()
		return () => window.removeEventListener("resize", handleResize)
	})

	const { activeLocale: locale } = useI18n()
	return (
		<Transition
			unmount={false}
			show={showMobileList}
			enter="transition ease-in-out duration-300 origin-top"
			enterFrom="transform-gpu translate-x-full md2:translate-x-0 md2:scale-y-0"
			enterTo="transform-gpu translate-x-0 md2:translate-x-0 md2:scale-y-100"
			leave="transition ease-in-out duration-200 origin-top"
			leaveFrom="transform-gpu translate-x-0 md2:translate-x-0 md2:scale-y-100"
			leaveTo="transform-gpu translate-x-full md2:translate-x-0 md2:scale-y-0"
			className={clsx(
				"fixed md2:relative inset-0 overflow-y-auto overflow-x-hidden",
				"bg-white text-black md2:bg-transparent md2:text-white",
				"md2:border-y border-gray-300 border-opacity-50 z-[53] md2:z-[51]",
				"fo-friendly",
				isSticky ? "md2:text-black shadow-gray" : "md2:text-white"
			)}
		>
			<div className="lg:container md2:px-5 flex items-center flex-wrap md2:flex-nowrap mt-6 md2:mt-0">
				<Text
					as="p"
					className={clsx(
						locale === "ar" && "slogan",
						isSticky ? "md2:text-black" : "",
						isSticky && "hidden",
						"px-5 py-6 md2:p-0 text-base md2:text-3xl font-bold leading-snug w-[146px] md2:w-[205px] md2:mr-auto md2:shrink-0"
					)}
				>
					{menu_slogon}
				</Text>
				<Hamburger
					isOpen={true}
					onClick={() => setShowMobileList(false)}
					className="md2:hidden"
				/>
				<nav
					className={clsx(
						isSticky
							? "justify-center"
							: "md2:w-2/3 md2:ltr:border-l md2:rtl:border-r md2:grow md2:ltr:ml-5 md2:rtl:mr-5 xl:grow-0",
						"w-full flex flex-col md2:flex-row",
						"divide-y md2:divide-y-0 md2:divide-x divide-gray-300 divide-opacity-50",
						`border-t border-b md2:border-0 border-gray-300 border-opacity-50 h-full`
					)}
				>
					{navigation.map((item) => (
						<span
							key={`main-menu-${item.id}`}
							className={clsx(
								// `${locale === "ar" ? "md2:py-6" : "md2:py-4"}`,
								// isSticky ? "lg:py-4" : `${locale === "ar" ? "lg:py-8" : "lg:py-6"}`,
								"flex relative group items-center text-left cursor-pointer",
								"md2:grow md2:shrink md2:basis-0 md2:w-0",
								"px-5 md2:px-3 lg:px-5",
								"py-8 md2:py-1",
								// "md2:min-h-[80px] lg:min-h-[100px]",
								isSticky ? "md2:min-h-[54px]" : "md2:min-h-[80px] lg:min-h-[100px]",
								"xl:basis-auto xl:grow-0 xl:w-auto"
							)}
							onClick={() => {
								hideSearch()
								if (item.url) {
									router.push(item.url)
									setShowID(null)
									setShowMobileList(false)
								} else {
									showID === item.id ? setShowID(null) : setShowID(item.id)
								}
							}}
						>
							<Text
								as="span"
								id={`main-menu-${item.id}`}
								className={clsx(
									"absolute inset-y-0 left-0 -z-1 md2:group-hover:w-full transition bg-secondary",
									showID === item.id ? "md2:w-full" : "w-0"
								)}
							></Text>
							<Text
								as="span"
								className={clsx(
									"md2:group-hover:text-white md2:grow lg:text-[15px] xl:text-base",
									isSticky && showID === item.id && "md2:text-white"
								)}
							>
								{item.title}
							</Text>
							{!item.url && (
								<>
									{showID === item.id ? (
										<Icon
											id="close-thin"
											className={clsx(
												isSticky && "group-hover:text-white text-black",
												showID === item.id && "md2:text-white",
												"shrink-0 fill-current text-black ml-auto md2:ml-4 h-4 w-4 transform -rotate-90 md2:transform-none"
											)}
											width="16px"
											height="16px"
										/>
									) : (
										<Icon
											id="chevron-down"
											className={clsx(
												isSticky && "group-hover:text-white text-black",
												showID === item.id && "md2:text-white",
												"shrink-0 fill-current rtl:rotate-90 md2:rtl:rotate-0 ml-auto md2:ml-4 h-4 w-4 transform -rotate-90 md2:transform-none"
											)}
											width="16px"
											height="16px"
										/>
									)}
								</>
							)}
						</span>
					))}
				</nav>
			</div>
		</Transition>
	)
}

const TopBar = ({
	topnavigation,
	dfComponentsCtaSocial,
	ctaSocialLabel,
	link,
	switchLocation,
	// showAccount,
	showOverlay,
}) => {
	return (
		<nav className="bg-white z-[53] md2:z-[60]">
			<div className="relative flex items-center md2:justify-between">
				<div className="flex items-center px-2 md2:px-0 ltr:mr-auto rtl:ml-auto ltr:ml-2 rtl:mr-2 space-x-2">
					{topnavigation.map((item, i) => {
						return (
							item.url && (
								<Link
									key={i}
									href={item.url}
									className="text-black text-2xs font-normal md2:text-sm md2:font-medium px-1 md2:px-2 py-1.5 hover:bg-gray-100"
								>
									{item.title}
								</Link>
							)
						)
					})}
				</div>
				<div className="flex-1 hidden md2:flex justify-end items-center lg:ml-6 text-black">
					<Text as="span" className="text-xs whitespace-nowrap">
						{ctaSocialLabel}
					</Text>
					<div className="flex">
						{dfComponentsCtaSocial.map((item, i) => {
							// Check if cta's class has a class that starts with "icon"
							return (
								item.link.title && (
									<Link
										key={`top_social_${i}`}
										{...item.link}
										className="bg-transparent border-0 mr-5"
									>
										<Icon
											id={item.link.id}
											width="20px"
											height="16px"
											className="fill-current"
										/>
									</Link>
								)
							)
						})}
					</div>
					<hr
						aria-orientation="vertical"
						className="w-px h-4 border-0 bg-gray-300 bg-opacity-40 transform translate-x-1 rtl:-translate-x-1"
					/>
					<Link
						href={link.url}
						className="text-black text-2xs font-normal md2:text-sm md2:font-medium px-1 md2:ml-1 md2:px-2 py-1.5 bg-primary !py-[14px]"
					>
						{link.title}
					</Link>
				</div>
				<div className="flex md2:hidden divide-x divide-gray-300 divide-opacity-50">
					<SearchButton onClick={showOverlay} color="black" />
					<div className="relative inline-block text-left fo-friendly">
						{switchLocation == 1 && <SwitchLocale />}
					</div>
				</div>

				{/* {showAccount == 1 && <UserInfo />} */}
			</div>
		</nav>
	)
}

const SwitchLocale = ({ isSticky = false }) => {
	const router = useRouter()
	const locale = router.locale
	const { path_18n } = useNode()

	return (
		<Menu as="div" className="relative inline-block text-left">
			<div>
				<Menu.Button
					className={clsx(
						"inline-flex items-center p-2 text-black hover:bg-white hover:bg-opacity-20 uppercase",
						isSticky ? "" : "md2:text-white"
					)}
				>
					{locale.toUpperCase()}
					<Icon
						id="chevron-down"
						className="h-3 w-3 ml-2 fill-current"
						width="20px"
						height="20px"
					/>
				</Menu.Button>
			</div>
			<Transition
				unmount={false}
				as={Fragment}
				enter="transition ease-out duration-100"
				enterFrom="transform opacity-0 scale-95"
				enterTo="transform opacity-100 scale-100"
				leave="transition ease-in duration-75"
				leaveFrom="transform opacity-100 scale-100"
				leaveTo="transform opacity-0 scale-95"
			>
				<Menu.Items className="ltr:origin-top-right rtl:origin-top-left absolute right-0 mt-2 w-auto bg-white text-black shadow-gray">
					<div className="flex flex-col py-1">
						{languages.map((language) => (
							<Menu.Item key={language.code}>
								{({ active }) => {
									const url = path_18n[language.code] || "#."
									return (
										<a
											href={url}
											className={`${
												active ? "bg-gray-100 text-secondary" : "text-gray-900"
											} block px-4 py-2 text-sm hover:bg-gray-100 hover:text-secondary`}
										>
											{language.label}
										</a>
									)
								}}
							</Menu.Item>
						))}
					</div>
				</Menu.Items>
			</Transition>
		</Menu>
	)
}

const FlyoutMenus = ({ showID, setShowID, isSticky, setShowMobileList }) => {
	const isHomepage = false
	// close on click outside
	useEffect(() => {
		const handleClickOutside = (e) => {
			if (e.target.closest(".fo-friendly") === null) {
				setShowID(null)
			}
		}

		document.addEventListener("mousedown", handleClickOutside)

		if (showID) {
			document.body.classList.add("overflow-hidden")
		} else {
			document.body.classList.remove("overflow-hidden")
		}

		return () => {
			document.removeEventListener("mousedown", handleClickOutside)
		}
	}, [setShowID, showID])

	const primaryMenuitems = useMenu("main")

	const menuItemClick = () => {
		setShowID(null)
		setShowMobileList(false)
	}

	// "|" to separate between before and after
	// after = right border
	// before = bottom border
	const separators = {
		2: ["|#"],
		3: ["|#", "|#"],
		4: ["|#", "|#", "|#"],
		5: ["#|#", "#|#", "#|", "|#"],
		6: ["#|#", "#|#", "#|", "|#", "|#"],
		7: ["#|#", "#|#", "#|#", "#|", "|#", "|#"],
		8: ["#|#", "#|#", "#|#", "#|", "|#", "|#", "|#"],
	}

	return (
		<div>
			<Transition
				unmount={false}
				show={!(showID === null)}
				enter="transition ease-in-out duration-300 transform-origin-top"
				enterFrom="transform-gpu translate-x-full md2:translate-x-0 md2:opacity-0 md2:scale-95"
				enterTo="transform-gpu translate-x-0 md2:translate-x-0 md2:opacity-100 md2:scale-100"
				leave="transition ease-in-out duration-200 transform-origin-top"
				leaveFrom="transform-gpu translate-x-0 md2:translate-x-0 md2:opacity-100 md2:scale-100"
				leaveTo="transform-gpu translate-x-full md2:translate-x-0 md2:opacity-0 md2:scale-95"
				className={clsx(
					"fixed inset-0 z-[53] md2:z-50 overflow-y-auto md2:overflow-y-visible", //md2:relative
					"bg-white text-black md2:bg-transparent md2:text-white",
					"fo-friendly",
					isSticky && "md2:top-[135px] lg:top-[135px]"
				)}
			>
				<div
					className={clsx(
						"md2:absolute top-0 inset-x-0 z-1 min-h-screen",
						"transform transition duration-300 h-0",
						((!isHomepage && isSticky && showID !== null) ||
							(isHomepage && isSticky && showID !== null)) &&
							"md2:bg-black md2:bg-opacity-80 z-2 h-full",
						!isHomepage && !isSticky && showID !== null && "z-2 h-full",
						!isSticky &&
							showID !== null &&
							"z-2 h-full md2:bg-black md2:bg-opacity-80 md2:text-white bg-white text-black"
					)}
				>
					<div
						className={clsx(
							"lg:container md2:px-5 flex-1 md2:flex items-center justify-center mb-10 md2:my-10",
							// !isSticky && "md2:mt-[278px] lg:mt-[300px]"
							!isSticky && "md2:mt-[246px] lg:mt-[266px]"
						)}
					>
						{primaryMenuitems.map((item) => {
							const secondLevel = item.below
							const hasThirdLevel = item.below?.some((m) => m.below)
							if (!secondLevel) return null

							let widthClass
							if ([8, 7, 4].includes(secondLevel.length)) widthClass = "md2:w-1/4"
							else if (secondLevel.length === 2) widthClass = "md2:w-1/2"
							else if ([1, 3, 5, 6].includes(secondLevel.length)) widthClass = "md2:w-1/3"

							if (hasThirdLevel)
								return (
									<div
										key={`2nd-level-wrap-${item.id}`}
										className={showID === item.id ? "block" : "hidden"}
									>
										<div
											className={clsx(
												"my-8 md2:sr-only mx-4 md2:mx-0"
												// isMenu ? "text-black" : "text-white"
											)}
										>
											<button className="mr-5" onClick={() => setShowID(null)}>
												<Icon
													id="chevron-left"
													width="32px"
													height="32px"
													className="w-8 h-8 inline rtl-icon"
												/>
											</button>
											<Text as="span" className="align-middle">
												{item.title}
											</Text>
										</div>
										{secondLevel.map((subitem, idx) => (
											<Fragment key={`3rd-level-wrap-${subitem.id}`}>
												{idx !== 0 && (
													<hr
														className="hidden md2:block border-t lg:border-[1.5px] border-white my-5"
														aria-hidden="true"
													/>
												)}
												<ThirdLevelMenu
													menuItem={subitem}
													clickHandler={menuItemClick}
													separators={separators}
												/>
											</Fragment>
										))}
									</div>
								)
							else {
								return (
									<div
										key={`2nd-level-wrap-${item.id}`}
										className={clsx(
											showID === item.id ? "block" : "hidden",
											secondLevel.length === 2 ? "md2:w-[70%]" : "w-full"
										)}
									>
										<div className={clsx("my-8 md2:sr-only mx-4 md2:mx-0")}>
											<button className="mr-5" onClick={() => setShowID(null)}>
												<Icon
													id="chevron-left"
													width="32px"
													height="32px"
													className="w-8 h-8 inline rtl-icon"
												/>
											</button>
											<Text as="span" className="align-middle">
												{item.title}
											</Text>
										</div>
										<ul
											className={clsx(
												"flex flex-col md2:flex-row md2:flex-wrap justify-center flex-wrap relative",
												"mx-auto",
												"divide-y md2:divide-y-0 divide-gray-300 divide-opacity-50",
												"border-t md2:border-t-0 border-gray-300 border-opacity-50"
											)}
										>
											{secondLevel.map((subitem, i) => (
												<li key={`2nd-level-item-${subitem.id}`} className={widthClass}>
													<MenuSubItem
														key={i}
														onClick={menuItemClick}
														{...subitem}
														separator={separators[secondLevel.length]?.[i]}
													/>
												</li>
											))}
										</ul>
									</div>
								)
							}
						})}
					</div>
				</div>
			</Transition>
		</div>
	)
}

const ThirdLevelMenu = ({ menuItem, clickHandler, separators }) => {
	const thirdLevelItems = menuItem.below || []
	const belowCount = thirdLevelItems.length
	const wClassMap = {
		1: "md2:w-1/1",
		2: "md2:w-1/2",
		3: "md2:w-1/3",
		4: "md2:w-1/4",
		5: "md2:w-1/5",
		6: "md2:w-1/6",
		7: "md2:w-1/7",
	}

	return (
		<div className="md2:flex">
			<div className="md2:w-32 mt-10 md2:mt-3 text-center md2:text-left">
				{menuItem.title !== "<none>" && (
					<>
						<Text as="span" className="text-2xl font-bold leading-none">
							{menuItem.title}
						</Text>
						<Icon
							id="awb-symbol"
							width="37px"
							height="10px"
							className="w-9 fill-current text-primary mb-4 mt-2 md2:mt-4 mx-auto md2:mx-0"
						/>
					</>
				)}
			</div>
			<ul
				className={clsx(
					"md2:flex flex-1 relative justify-center",
					// "px-4 md2:px-0",
					"divide-y md2:divide-y-0 divide-gray-300 divide-opacity-50",
					"border-t md2:border-t-0 border-gray-300 border-opacity-50"
				)}
			>
				{thirdLevelItems?.map((subitem, i) => (
					<li key={`3rd-level-item-${subitem.id}`} className={wClassMap[belowCount]}>
						<MenuSubItem
							onClick={clickHandler}
							{...subitem}
							separator={separators[thirdLevelItems.length]?.[i]}
						/>
					</li>
				))}
			</ul>
		</div>
	)
}

const MenuSubItem = ({ url, title, options, onClick, separator = "|" }) => {
	const [before, after] = separator.split("|")
	const itemClass = options?.attributes?.class?.[0] || "icon-question"

	const iconId = itemClass.match(/icon-[a-zA-Z0-9-]+/)
		? itemClass.match(/icon-[a-zA-Z0-9-]+/)[0]?.substring(5)
		: "question"

	const afterClass =
		"after:content-['_'] after:absolute after:h-full after:w-px lg:after:w-[2px] after:right-0 after:top-0 after:block after:bg-white"
	const beforeClass =
		"before:content-['_'] before:absolute before:h-px lg:before:h-[2px] before:w-full before:left-0 before:bottom-0 before:block before:bg-white"

	return (
		<div
			className={clsx(
				"md2:hover:text-white md2:text-center group w-full h-full relative",
				after && afterClass,
				before && beforeClass
			)}
		>
			<Link
				href={url}
				className="md2:h-full flex md2:flex-col items-center md2:justify-center py-6 md2:px-8 md2:group-hover:bg-secondary group-hover:-ml-px" //md2:h-48
				onClick={onClick}
			>
				<div className="relative mx-4 w-10 h-10 md2:w-14 md2:h-14 md2:mb-4">
					<Icon
						id={iconId}
						className="group-hover:invisible h-full w-full fill-current"
					/>
					<svg
						className="invisible group-hover:visible absolute inset-0"
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						viewBox="0 0 24 24"
						stroke="currentColor"
					>
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth={2}
							d="M12 6v6m0 0v6m0-6h6m-6 0H6"
						/>
					</svg>
				</div>
				<Text as="span" className="text-base md2:text-lg font-medium">
					{title}
				</Text>
			</Link>
		</div>
	)
}

export default HeaderComponent
